import React from "react"
//import { Link } from "gatsby"

const Footer = ({ siteTitle }) => {
  return (
    <footer className="footer">
      <div className="footer-belt">
        <div className="company-sign">
          &copy; {new Date().getFullYear()} {siteTitle}
        </div>
        <div className="privacy">
          <a
            //href="https://www.iubenda.com/privacy-policy/18132272"
            href="https://blog.shoppingtracker.app/"
            target="_blank"
            //rel="noreferrer"
            title="Artiles"
          >
            Articles
          </a>
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
          <a
            //href="https://www.iubenda.com/privacy-policy/18132272"
            href="/privacyPolicy" //"https://shoppingtracker.app/privacypolicy"
            target="_blank"
            //rel="noreferrer"
            title="Privacy Policy "
          >
            Privacy Policy
          </a>
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
          <a
            href="/imprint" //"https://shoppingtracker.app/imprint"
            target="_blank"
            //rel="noreferrer"
            title="Imprint/Impressum"
          >
            Imprint/Impressum
          </a>
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
          <a
            href="/tos"
            target="_blank"
            //rel="noreferrer"
            title="Terms and conditions"
          >
            Terms and conditions
          </a>
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
          <p>For more information contact: info@shoppingtracker.app</p>
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        </div>
      </div>
    </footer>
  )
}

export default Footer
